import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import { HeaderTitle, StyledToolbar, StyledHeaderRight } from './HeaderStyle';
import { loginModalHandler } from '../../../pages/LoginPage/LoginDucks';
import HelpButton from './HelpButton';
import paths from '../../../_helpers/paths';
import Logo from '../../../_assets/images/logo.png';
import { history } from '../../../_helpers/history';
import LocaleButton from './LocaleButton';
import './HeaderTranslate';
import { Content } from '../../../components/ThemeProvider/ThemeStyle';
import LinkApp from './../../../pages/Market/LinkApp';
import CollectionBlock from './CollectionBlock';

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#3433B8',
    paddingTop: '15px !important',
    paddingBottom: '15px !important',
    paddingLeft: '20px !important',
    paddingRight: '20px !important',
    borderRadius: '10px',
    border: 'none',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    lineHeight: '140%',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#5250F9',
      color: 'white',
    },
    '@media (max-width:1050px)': {
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      fontSize: '12px',
      textAlign: 'center',
    }
  }
}))(Button);

function HeaderWithoutAuth({contentWidth, isCurrent}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const home = location.pathname === paths.homePage;
  return (
    <>
      {
        home ?
          <AppBar position={isCurrent ? 'absolute' : 'static'} style={{ borderBottom: 'none' }} elevation={0}
                  color="transparent" id="header">
            <CollectionBlock />
            <Content contentWidth={contentWidth}>
              <StyledToolbar>
                <HeaderTitle onClick={() => history.push('/')}>
                  <img src={Logo} alt="Logo" />
                  <li
                    style={{ color: home ? '#fff' : '#3433B8', fontWeight: home ? '600' : '400' }}
                  >
                    {t('home_title')}
                  </li>
                </HeaderTitle>
                <StyledHeaderRight>
                  <LinkApp />
                  <ColorButton
                    size="small"
                    target="_blank"
                    href="https://egov.kz/cms/ru/services/pass_onlineecp"
                    variant="outlined"
                    children={t('header_getEDS')}
                  />
                  <ul>
                    <li onClick={() => dispatch(loginModalHandler(true))}
                        style={{ color: home ? '#fff' : '#3433B8', fontWeight: home ? '600' : '400' }}>
                      {t('header_myProfile')}
                    </li>
                    <HelpButton />
                    <LocaleButton />
                  </ul>
                </StyledHeaderRight>
              </StyledToolbar>
            </Content>
          </AppBar> :
          <AppBar position={isCurrent ? 'absolute' : 'static'}
                  style={{ borderBottom: isCurrent && 'none'}} elevation={0} color="transparent"
                  id="header">
            <Content contentWidth={contentWidth}>
              <StyledToolbar>
                <HeaderTitle>
                  <img src={Logo} alt="Logo" />
                  <li
                    style={{ whiteSpace: 'pre-line' }}
                  onClick={() => history.push('/')}
                >
                  {t('home_title')}
                      </li>
              </HeaderTitle>
              <ul>
                <LinkApp />
                <li onClick={() => dispatch(loginModalHandler(true))} style={{ color: home ? '#fff' : '#3433B8' }}>
                  {t('header_login')}
                </li>
                <HelpButton/>
                <LocaleButton/>
                <ColorButton
                  size="small"
                  target="_blank"
                  href="https://egov.kz/cms/ru/services/pass_onlineecp"
                  variant="outlined"
                  children={t('header_getEDS')}
                />
              </ul>
            </StyledToolbar>
          </Content>
        </AppBar>
      }
    </>
    
  );
}

export default HeaderWithoutAuth;
