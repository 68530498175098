import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';

import { accountSettingsModule, loadRequesterMeta, saveRequesterData, getPerson  } from './AccountSettingsDucks';
import Button from '../../modules/Button/Button';
import PassportForm from '../../passport/PassportForm';
import { getFormValues } from '../../passport/Passport';
import PassportProvider from '../../passport/PassportProvider';
import Row from '../../_ui/Row/Row';
import { RowContainer } from './AccountSettingsStyles';
import Input from '../../_ui/Input/Input';
import { loginModule } from '../LoginPage/LoginDucks';
import Notice from '../../modules/utils/Notice';
import './AccountSettingsTranslate';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

function AccountSettings(props) {
  const [disabled, setDisabled] = useState(true);
  const { meta, loading, loadingSave, requesterData, requester } = props;
  const { saveRequesterData, loadRequesterMeta } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!requester['user_email'] || !requester['user_telephone_number']) {
      Notice.info(t('accountSettings_fillForm'));
    }
    if (requester.iin) dispatch(getPerson(props.user?.username))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requester]);

  useEffect(() => {
    loadRequesterMeta();
  }, [loadRequesterMeta]);

  return (
    <div className="fullWidth mt3 mb3">
      {disabled && (
        <div className="right-align mb2">
          <Button
            color="secondary"
            text={t('edit')}
            icon={<EditIcon style={{ color: 'white', marginRight: 10 }}/>}
            onClick={() => setDisabled(false)}
          />
        </div>
      )}
      <div>
        <PassportProvider
          config={{
            fullDatePicker: true,
            documentsProps: {
              mimeType: 'image/jpeg,image/png,application/pdf',
              maxSize: 10000000,
            },
          }}
          withoutScroll={false}
          data={requesterData}
          meta={meta}
          children={
            <PassportForm
              disabled={disabled}
              betweenRender={(_, section) => {
                if (section === 'REQUESTER_SHORT.user_surname') {
                  return (
                    <RowContainer>
                      <Row
                        label={t('accountSettings_iin')}
                        children={
                          <Input
                            withoutForm
                            disabled
                            value={requesterData.iin}
                          />
                        }
                      />
                    </RowContainer>
                  );
                }
              }}
              loading={loading}
              buttonRender={btnLoading =>
                !disabled && (
                  <div className="mt2 right">
                    <Button
                      text={t('cancel')}
                      disabled={loadingSave}
                      color="secondary"
                      onClick={() => setDisabled(true)}
                    />
                    <span className="mr1"/>
                    <Button
                      text={t('save')}
                      loading={btnLoading || loadingSave}
                      color="primary"
                      onClick={() => {
                        getFormValues().then(changes => {
                          if (changes && changes[meta.id])
                            saveRequesterData(
                              {
                                id: requesterData.id,
                                iin: requesterData.iin,
                                requesterChanges: changes[meta.id]
                              },
                              () => setDisabled(true)
                            );
                        });
                      }}
                    />
                  </div>
                )
              }
            />
          }
        />
        <div className='mt2'>
          <p>{t('accountSettingsDucks_lastLoginDt')}: {moment(props.account?.lastLoginDt).format("DD:MM:YYYY")}</p>
        </div>
      </div>
    </div>
  );
}

export default connect(
  state => ({
    meta: state[accountSettingsModule].meta,
    requesterData: state[loginModule].requesterData,
    loading: state[accountSettingsModule].loading,
    loadingSave: state[accountSettingsModule].loadingSave,
    requester: state[loginModule].requester,
    user: state[loginModule].user,
    account: state[accountSettingsModule].account,
  }),
  {
    saveRequesterData,
    loadRequesterMeta
  }
)(AccountSettings);
