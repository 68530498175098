import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { changeFormValue } from '../../../passport/Passport';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import { capitalize } from '@material-ui/core';

const requestGBDFL = async ({ setLoading, code, value, t, lang }) => {
  try {
    setLoading(true);
    let c = code.split('.')[1];
    const metadataKey = code.split('.')[0];
    const data = await ServiceRequestApi.getPersonFlBy(value);
    let user = null;
    if (data.status === 200) { 
      if (data.data.content.length > 0) {
        data.data.content.forEach(item => {
          let obj = {};
          item.passportValues.forEach(a => {
              obj[a.code] = a.value;
          });
          user = obj
        });
        switch (code) {
          case 'request_form_children_adoption_short.guardianship_child_iin':
          case 'request_form_payment_patronage_short.spouse_iin':
          case 'request_form_children_property_short.spouse_iin':
          case 'request_form_children_property_short.child_iin':
          case 'request_form_children_property_short.previous_owner_iin':
          case 'request_form_doc_acceptance_ddo_short.child_iin':
          case 'request_form_payment_family_short.spouse_iin':
          case 'request_form_accept_doc_edu_short.divorce_spouse_iin':
            c = c.replace('_iin', '');
            changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
            changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
            changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
            changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
            break;
          case 'request_form_accept_doc_edu_short.Child_iin':
            changeFormValue(`${metadataKey}.child_surname`, user['surname']);
            changeFormValue(`${metadataKey}.child_name`, user['name']);
            changeFormValue(`${metadataKey}.child_middlename`, user['middlename']);
            changeFormValue(`${metadataKey}.child_birthday`, user['date_of_birth']);
            const typeApplic = window.PassportForm.values['request_form_accept_doc_edu_short.Type_applic'];
            if (typeApplic === '0') {
              Notice.info(t('serviceRequest_mustBeSixYearsOld'));
            }
            changeFormValue(`${metadataKey}.NameZAGS_ru`, user['birthCertificateIssueOrganization']);
            changeFormValue(`${metadataKey}.NameZAGS_kk`, user['birthCertificateIssueOrganization']);
            changeFormValue(`${metadataKey}.numberAkt`, user['birthCertificateNumber']);
            changeFormValue(`${metadataKey}.reg_date_child`, user['birthDate']);
            changeFormValue(`${metadataKey}.date_issue_ZAGS`, user['birthCertificateBeginDate']);
           /* const response = await ServiceRequestApi.getSchools(value);
            if (response.status === 200) {
              if (response.data.content.length) {
                const s = response.data.content.find(s => s.status === 0);
                if (s) {
                  const edSign = document.getElementById('anchor_request_form_accept_doc_edu_short.education_sign');
                  if (edSign) {
                    Notice.error(<div
                      dangerouslySetInnerHTML={{ __html: t('serviceRequest_alreadyStudy', { schoolName: s[`schoolName${capitalize(lang)}`] }) }}/>);
                  }
                  changeFormValue(`${metadataKey}.education_sign`, true);
                  const ruName = s['schoolNameRu'];
                  const kkName = s['schoolNameKk'];
                  const arrivalDate = s.passportValues.find(q => q.code === 'DateOfArrival');
                  const parallel = s.passportValues.find(q => q.code === 'Parallel');
                  const formStudy = s.passportValues.find(q => q.code === 'StudyForm');
                  changeFormValue(`${metadataKey}.School_Name_kk_name`, kkName);
                  changeFormValue(`${metadataKey}.School_Name_ru_name`, ruName);
                  changeFormValue(`${metadataKey}.DateOfArrival`, arrivalDate ? arrivalDate.value : '');
                  changeFormValue(`${metadataKey}.Parallel`, parallel ? parallel.value.value : '');
                  changeFormValue(`${metadataKey}.StudyForm`, formStudy ? formStudy.value.value : '');
                } else {
                  changeFormValue(`${metadataKey}.education_sign`, false);
                }
              } else {
                changeFormValue(`${metadataKey}.education_sign`, false);
              }
            }*/
            break;
          case 'request_form_accept_doc_edu_short.mother_iin':
            changeFormValue(`${metadataKey}.mother_surname`, user['surname']);
            changeFormValue(`${metadataKey}.mother_name`, user['name']);
            changeFormValue(`${metadataKey}.mother_secondname`, user['middlename']);
            changeFormValue(`${metadataKey}.mother_birthDate`, user['date_of_birth']);
            break;
          case 'request_form_accept_doc_edu_short.father_iin':
            changeFormValue(`${metadataKey}.father_surname`, user['surname']);
            changeFormValue(`${metadataKey}.father_name`, user['name']);
            changeFormValue(`${metadataKey}.father_secondname`, user['middlename']);
            changeFormValue(`${metadataKey}.father_birthDate`, user['date_of_birth']);
            break;
          case 'request_form_accept_doc_edu_short.iin_spouse':
            c = c.replace('iin_', '');
            changeFormValue(`${metadataKey}.surname_${c}`, user['lastName']);
            changeFormValue(`${metadataKey}.name_${c}`, user['firstName']);
            changeFormValue(`${metadataKey}.secondname_${c}`, user['middleName']);
            changeFormValue(`${metadataKey}.birthDate_${c}`, user['birthDate']);
            break;
          case 'request_form_children_adoption_short.iin_child_mother':
          case 'request_form_children_adoption_short.iin_child_father':
            c = c.replace('iin_', '');
            changeFormValue(`${metadataKey}.surname_${c}`, user['lastName']);
            changeFormValue(`${metadataKey}.name_${c}`, user['firstName']);
            changeFormValue(`${metadataKey}.middlename_${c}`, user['middleName']);
            break;
          case 'request_form_doc_queued_ddo_short.child_iin':
            changeFormValue(`${metadataKey}.child_surname`, user['lastName']);
            changeFormValue(`${metadataKey}.child_name`, user['firstName']);
            changeFormValue(`${metadataKey}.child_middlename`, user['middleName']);
            changeFormValue(`${metadataKey}.child_birthday`, user['birthDate']);
            changeFormValue(`${metadataKey}.name_reg_birth_child`, user['birthCertificateIssueOrganization']);
            changeFormValue(`${metadataKey}.name_reg_birth_child_kz`, user['birthCertificateIssueOrganization']);
            changeFormValue(`${metadataKey}.number_reg_child`, user['birthCertificateNumber']);
            changeFormValue(`${metadataKey}.reg_date_child`, user['birthDate']);
            changeFormValue(`${metadataKey}.birthdoc_doc_date_child`, user['birthCertificateBeginDate']);
            break;
          default:
            break;
        }
      } else {
        Notice.error(t('gbdflButton_iinNotFound_5'));
      }  
    }
    // const user = data.result;

  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.gbdfl_person_not_found') {
      const metadata = code.split('.')[0];
      if (
        [
          'request_form_doc_queued_ddo_short'
        ].includes(metadata)
      ) {
        Notice.error(t('gbdflButton_iinNotFound_2'));
      } else if (['request_form_accept_doc_edu_short'].includes(metadata)) {
        Notice.error(t('gbdflButton_iinNotFound_5'));
      } else {
        Notice.error(t('gbdflButton_iinNotFound'));
      }
    } else {
      handleError(error, t('gbdflButton_searchFailed'));
    }
  } finally {
    setLoading(false);
  }
};

function SimpleGBDFLButton({ code, value }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <StyledGBDFLButton
        color="secondary"
        loading={loading}
        text={t('gbdflButton_search')}
        disabled={value.length !== 12}
        onClick={() => requestGBDFL({ setLoading, code, value, t, lang: i18n.language })}
      />
    </div>
  );
}

export default SimpleGBDFLButton;
