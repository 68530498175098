import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';

import { StyledDialog, StyledLink } from './VideoInstructionsStyles';
import './VideoInstructionsTranslate';

const videoInstructions = [
  {
    ru: '/server/folder/media/ACCEPT_DOC_EDU_RU_PEP.mp4',
    kk: '/server/folder/media/ACCEPT_DOC_EDU_KK_PEP.mp4',
    ru_name: 'Прием документов и зачисление в организации образования (начальные, осн. ср., общ.ср, спец.)',
    kk_name: 'Білім беру ұйымдарына құжаттарды қабылдау және оқуға қабылдау (мектепке дейінгі, бастауыш, негізгі орта, жалпы орта, арнайы)'
  },
  {
    ru: '/server/folder/media/ACADEMICAL_RESIGNATIONS_RU_PEP.mp4',
    kk: '/server/folder/media/ACADEMICAL_RESIGNATIONS_KK_PEP.mp4',
    ru_name: 'Предоставление академических отпусков обучающимся в организациях образования',
    kk_name: 'Білім беру ұйымдарында білім алушыларға академиялық демалыстар беру'
  }
];

function VideoInstructions() {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(null);

  return (
    <div className="mb3 mt3 fullWidth">
      <Typography
        children={t('videoInstructions_title')}
        color="secondary"
        variant="h6"
      />
      <div className="mb3"/>
      {videoInstructions.map(
        (item, key) =>
          <Card
            key={key}
            className="my1 p2 pointer flex items-center"
            variant="outlined"
            onClick={() => setSelected(item)}
          >
            <PlayIcon color="action" className="mr2"/>
            <StyledLink>{item[`${i18n.language}_name`]}</StyledLink>
          </Card>
      )}
      {selected && (
        <StyledDialog open={true} onClose={() => setSelected(null)} maxWidth="lg">
          <div className="p2 bold">{selected[`${i18n.language}_name`]}</div>
          <video key={i18n.language} width="100%" controls="controls">
            <source
              src={selected[i18n.language]}
              type="video/mp4"
            />
          </video>
        </StyledDialog>
      )}
    </div>
  );
}

export default VideoInstructions;