import { createReducer } from 'redux-starter-kit';
import i18next from 'i18next';

import { ServiceProvidersApi } from './ServiceProvidersApi';
import { handleError } from '../../../modules/utils/handleError';
import { serviceRequestModule } from '../ServiceRequestDucks';

export const serviceProvidersModule = 'serviceProviders';
const LOADING = `${serviceProvidersModule}/LOADING`;
const TABLE_DATA = `${serviceProvidersModule}/TABLE_DATA`;
const CLEAR_STATE = `${serviceProvidersModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  tableData: {
    content: [],
    totalElements: 0
  }
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadTableData = filter => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, payload: true });
    delete filter.pageSize
    let metadataKey = getState()[serviceRequestModule].meta.id;
    metadataKey = metadataKey.replace('request_form_', '').replace('_short', '');
    if (!filter.hasOwnProperty('extraFilters')) {
      filter['extraFilters'] = {};
    } else {
      filter['extraFilters'] = JSON.parse(filter['extraFilters']);
    }
    const customFilters = {
      ...filter,
      ...filter['extraFilters']
    };
    if (!customFilters['locationCode']) {
      customFilters['locationCode'] = '190000000';
    }
    delete customFilters['extraFilters'];
    let filterString = Object.keys(customFilters).map(key => {
      let value = customFilters[key];
      return `${key}=${value}`;
    }).join('&');
    filterString = `${filterString}&serviceType=${metadataKey}`;
    let { data } = await ServiceProvidersApi.loadTableData(filterString);

    if (data.content) {
      const content = [];
      for (let row of data.content) {
        const item = {};
        for (let r of row.passportValues) {
          item[r.code] = r.value;
        }
        content.push(item);
      }

      dispatch({
        type: TABLE_DATA, payload: { ...data, content }, filter
      });
    } else {
      handleError(data, i18next.t('serviceProviders_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
