import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledServicesCards } from './HomeSectionStyles';
import { Content } from '../../../components/ThemeProvider/ThemeStyle';
import {home_services} from '../homeServices'
import HomeServicesCard from './HomeServicesCard';

function HomeServices({contentWidth}) {
  const { t } = useTranslation();

  return (
    <Content contentWidth={contentWidth}>
        <StyledServicesCards>
          {Object.values(home_services).map((metadata, index) => (
            <HomeServicesCard
              key={index}
              metadata={metadata}
            />
            ))
          }
        </StyledServicesCards>
    </Content>
  );
}

export default HomeServices;
