import React from 'react';

function Checkboxes ({ label, lang, options, setOptions }){

  const setChecked = (code) => {
    let new_options = [...options];
    new_options.map(item => {
      if(item.code === code) item.checked = !item.checked;
      return item;
    });
    setOptions(new_options);
  }
  console.log(options)
  return (
    <div style={{padding:'5px', marginTop: '10px'}}>
      <div style={{textAlign: 'center', borderBottom: '1px solid black',marginBottom: '10px'}}>
        {label}
      </div>
      {options.filter(i => i.View === 'true').map(item =>{
        return (
          <div key={item.code}>
            <label>
              <input type="checkbox" checked={item.checked} onChange={() => setChecked(item.code)} style={{margin: '0px 5px'}}/>
              {lang === 'ru' ? item.ru_name : item.kk_name}
            </label>
          </div>
        )
      })
      }
    </div>
  );
};
export default Checkboxes;
