import paths from './paths';
import Home from '../pages/Home/Home';
import ServiceRequest from '../pages/ServiceRequest/ServiceRequest';
import Account from '../pages/Account/Account';
import Requests from '../pages/Requests/Requests';
import CompletedRequests from '../pages/CompletedRequests/CompletedRequests';
import AccountSettings from '../pages/AccountSettings/AccountSettings';
import Instructions from '../pages/Instructions/Instructions';
import VideoInstructions from '../pages/VideoInstructions/VideoInstructions';
import FAQ from '../pages/FAQ/FAQ';
import GovServices from '../pages/GovServices/GovServices';
import KindergartenServices from '../pages/KindergartenServices/KindergartenServices';
import CheckQueueNumber from '../pages/CheckQueueNumber/CheckQueueNumber';
import QueueApplications from '../pages/QueueApplications/QueueApplications';
import KindergartenApplications from '../pages/KindergartenApplications/KindergartenApplications';
import KindergartenAppointments from '../pages/KindergartenAppointments/KindergartenAppointments';
import KindergartenNotifications from '../pages/KindergartenNotifications/KindergartenNotifications';
import KindergartenReestr from '../pages/KindergartenReestr/KindergartenReestr';
import BulletinOpenPlaces from '../pages/BulletinOpenPlaces/BulletinOpenPlaces';
import KindergartenReestrView from '../pages/KindergartenReestr/KindergartenReestrView/KindergartenReestrView';
import MarketAndroidPage from '../pages/Market/MarketAndroidPage';

const routerProps = {
  homePage: {
    path: paths.homePage,
    component: Home,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  kindergartenServices: {
    path: paths.kindergartenServices,
    component: KindergartenServices,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  govServices: {
    path: paths.govServices,
    component: GovServices,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  checkQueueNumber: {
    path: paths.checkQueueNumber,
    component: CheckQueueNumber,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  queueApplications: {
    path: paths.queueApplications,
    component: QueueApplications,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: false
  },
  bulletinOpenPlaces: {
    path: paths.bulletinOpenPlaces,
    component: BulletinOpenPlaces,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: false
  },
  bulletinOpenPlacesKqId: {
    path: paths.bulletinOpenPlacesKqId,
    component: BulletinOpenPlaces,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  kindergartenReestr: {
    path: paths.kindergartenReestr,
    component: KindergartenReestr,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: false
  },
  kindergartenReestrById: {
    path: paths.kindergartenReestrById,
    component: KindergartenReestrView,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  createRequestPage: {
    path: paths.createRequestPage,
    component: ServiceRequest,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: false
  },
  accountPage: {
    path: paths.accountPage,
    component: Account,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  requestsPage: {
    path: paths.requestsPage,
    component: Requests,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  completedRequestsPage: {
    path: paths.completedRequestsPage,
    component: CompletedRequests,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  accountSettingsPage: {
    path: paths.accountSettingsPage,
    component: AccountSettings,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  viewRequestFormPage: {
    path: paths.viewRequestFormPage,
    component: ServiceRequest,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: false
  },
  instructionsPage: {
    path: paths.instructionsPage,
    component: Instructions,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  videoInstructionsPage: {
    path: paths.videoInstructionsPage,
    component: VideoInstructions,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  faqPage: {
    path: paths.faqPage,
    component: FAQ,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  kindergartenApplications: {
    path: paths.kindergartenApplications,
    component: KindergartenApplications,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  kindergartenAppointments: {
    path: paths.kindergartenAppointments,
    component: KindergartenAppointments,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  kindergartenNotifications: {
    path: paths.kindergartenNotifications,
    component: KindergartenNotifications,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  marketAndroidPage: {
    path: paths.marketAndroidPage,
    component: MarketAndroidPage,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
};

export const headerPaths = Object.values(routerProps)
  .filter(item => !item.withoutHeader)
  .map(item => item.path);

export const sideBarPaths = Object.values(routerProps)
  .filter(item => !item.withoutSideBar)
  .map(item => item.path);

export default routerProps;
