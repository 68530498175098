import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import Colors from '../../ThemeProvider/Colors';
import { StyledSelectButton } from './HeaderStyle';
import { history } from '../../../_helpers/history';
import unionIcon from '../../../_assets/images/unionIcon.svg'
import unionIconColor from '../../../_assets/images/unionIconColor.svg'

function HelpButton() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const home = history.location.pathname === '/';

  return (
    <>
      {
        history.location.pathname !== '/kindergarten-services' &&
        <>
          <StyledSelectButton onClick={e => {
            setAnchorEl(e.currentTarget);
            setOpen(true);
          }} style={{ color: home ? '#fff' : '#3433B8', fontWeight: home ? '600' : '400' }}>
            {t('header_help')}
            {
              home ? 
              <img src={unionIcon} alt=''/>
              : 
              <img src={unionIconColor} alt=''/>
            }
          </StyledSelectButton>
          <Menu
            open={open}
            anchorEl={anchorEl}
            style={{ marginTop: 40 }}
            MenuListProps={{ style: { minWidth: 120 } }}
            onClose={() => setOpen(false)}
          >
            <MenuItem
              style={{ color: Colors.action }}
              onClick={() => {
                window.open('https://gualmobl.supportsystem.com/index.php', '_blank');
                setOpen(false);
              }}
            >
              <Typography children={t('header_support')}/>
            </MenuItem>
            <MenuItem
              style={{ color: Colors.action }}
              onClick={() => {
                history.push('/faq');
                setOpen(false);
              }}
            >
              <Typography children={t('header_faq')}/>
            </MenuItem>
            <MenuItem
              style={{ color: Colors.action }}
              onClick={() => {
                history.push('/instructions');
                setOpen(false);
              }}
            >
              <Typography children={t('header_guide')}/>
            </MenuItem>
            <MenuItem
              style={{ color: Colors.action }}
              onClick={() => {
                history.push('/video-instructions');
                setOpen(false);
              }}
            >
              <Typography children={t('header_video')}/>
            </MenuItem>
          </Menu>
        </>
      }
    </>
  );
}

export default HelpButton;