import styled from 'styled-components';

export const StyledMarketDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 120px;
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    img {
      width: 100px;
      cursor: pointer;
    }
  }
`

export const StyledMarketText = styled.p`
  text-align: center;
  @media screen and (max-width: 900px) {
    padding: 0 20px;
  }
`

export const StyledBlockClose = styled.div`
  position: absolute; 
  top: 10px; 
  right: 10px;
  @media screen and (max-width: 900px) {
    top: 5px; 
    right: 5px;
  }
`