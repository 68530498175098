import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyledSelectButton } from './HeaderStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

import Colors from '../../ThemeProvider/Colors';
import { history } from '../../../_helpers/history';
import { logout } from '../../../pages/LoginPage/LoginDucks';
import useUserInfo from '../../_hooks/useUserInfo';
import paths from '../../../_helpers/paths';

function AccountButton() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useUserInfo();
  const home = history.location.pathname === '/';

  return (
    <>
      <StyledSelectButton onClick={e => {
        setAnchorEl(e.currentTarget);
        setOpen(true);
      }}
      style={{ color: home ? '#fff' : '#3433B8' }}
    >
        <PersonOutlineIcon className="icon left" style={{ color: home ? '#fff' : '#3433B8' }} />
        {_.capitalize(user.firstName)}
        <ExpandMoreIcon className="icon" style={{ color: home ? '#fff' : '#3433B8' }} />
      </StyledSelectButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        style={{ marginTop: 40 }}
        MenuListProps={{ style: { minWidth: 120 } }}
        onClose={() => setOpen(false)}
      >
        <MenuItem style={{ color: Colors.action, background: history.location.pathname === paths.accountPage && 'linear-gradient(90deg, #f1f1f1 20%, #ffffff 100%)' }} onClick={() => {
          history.push(paths.accountPage);
          setOpen(false);
        }}>
          <Typography children={t('header_myProfile')}/>
        </MenuItem>
        <MenuItem style={{ color: Colors.action, background: history.location.pathname === paths.accountSettingsPage && 'linear-gradient(90deg, #f1f1f1 20%, #ffffff 100%)' }} onClick={() => {
          history.push(paths.accountSettingsPage);
          setOpen(false);
        }}>
          <Typography children={t('header_account')}/>
        </MenuItem>
        <MenuItem style={{ color: Colors.action }} onClick={() => {
          dispatch(logout());
          setOpen(false);
        }}>
          <Typography children={t('header_exit')}/>
        </MenuItem>
      </Menu>
    </>
  );
}

export default AccountButton;