import React from 'react';
import _ from 'lodash';
import {
  CHANGE_HIDDEN_COMPLEX_QUESTIONS,
  SIMPLE_GUARDIANSHIP_QUESTIONS,
  SIMPLE_SCHOOL_QUESTIONS,
  SIMPLE_IIN_QUESTIONS,
  SP_MODAL_TRIGGERS,
  COMPLEX_IIN_QUESTIONS,
  COMPLEX_SCHOOL_QUESTIONS,
  COMPLEX_COLLEGE_QUESTIONS,
  COMPLEX_UNIVER_QUESTIONS,
  CHANGE_SIMPLE_QUESTIONS,
  LISTEN_SIMPLE_QUESTIONS,
  CHECK_AGE_QUESTIONS, CHECK_IIN_IN_QUEUE
} from './constants';
import SPModalButton from './SPModalButton';
import { changeHiddenQuestions } from './ChangeHiddenQuestions';
import SimpleGBDFLButton from './SimpleGBDFLButton';
import SimpleGuardianship from './SimpleGuardianship';
import SimpleSchool from './SimpleSchool';
import ComplexGBDFLButton from './ComplexGBDFLButton';
import ComplexSchool from './ComplexSchool';
import ComplexCollege from './ComplexCollege';
import ComplexUniver from './ComplexUniver';
import ChangeSimpleQuestions from './ChangeSimpleQuestions';
import ListenSimpleQuestions from './ListenSimpleQuestions';
import CheckAgeQuestions from './CheckAgeQuestions';
import i18next from 'i18next';
import { StyledGBDFLButton } from '../ServiceRequestStyles';
import CheckIinInQueueButton from './CheckIinInQueueButton'
import DocAcceptanceDDOShortButton from './DocAcceptanceDDOShortButton'

export function extraFunctionality({ code, value, SPModal, requesterIin, setDisabled, selectDDOModal }) {
  switch (typeof code) {
    case 'object':
      const { questionCode, complexCode } = code;
      const ctCode = code.code.split('.').pop();
      if (_.get(CHANGE_HIDDEN_COMPLEX_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return changeHiddenQuestions({
          code: code.code,
          questionCode,
          complexCode,
          value
        });
      } else if (SP_MODAL_TRIGGERS.includes(questionCode)) {
        return <SPModalButton code={code} SPModal={SPModal} />;
      } else if (_.get(COMPLEX_IIN_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return (
          <ComplexGBDFLButton
            code={code.code}
            questionCode={questionCode}
            complexCode={complexCode}
            value={value}
          />
        );
      } else if (_.get(COMPLEX_SCHOOL_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return <ComplexSchool code={code.code} complexCode={complexCode} />;
      } else if (_.get(COMPLEX_COLLEGE_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return (
          <ComplexCollege
            code={code.code}
            complexCode={complexCode}
            requesterIin={requesterIin}
          />
        );
      } else if (_.get(COMPLEX_UNIVER_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return (
          <ComplexUniver
            code={code.code}
            complexCode={complexCode}
            requesterIin={requesterIin}
          />
        );
      }
      break;
    case 'string':
      if (SIMPLE_IIN_QUESTIONS.includes(code)) {
        return <SimpleGBDFLButton code={code} value={value} />;
      } else if (SP_MODAL_TRIGGERS.includes(code)) {
        return <SPModalButton code={code} SPModal={SPModal} />;
      } else if (SIMPLE_GUARDIANSHIP_QUESTIONS.includes(code)) {
        return <SimpleGuardianship code={code} requesterIin={requesterIin} />;
      } else if (SIMPLE_SCHOOL_QUESTIONS.includes(code)) {
        return <SimpleSchool code={code} />;
      } else if (CHANGE_SIMPLE_QUESTIONS.includes(code)) {
        return <ChangeSimpleQuestions code={code} requesterIin={requesterIin} />;
      } else if (LISTEN_SIMPLE_QUESTIONS.includes(code)) {
        return <ListenSimpleQuestions code={code} value={value} />;
      } else if (CHECK_AGE_QUESTIONS.includes(code) && value) {
        return <CheckAgeQuestions code={code} value={value} setDisabled={setDisabled} />;
      } else if (CHECK_IIN_IN_QUEUE.includes(code) && value) {
        return <CheckIinInQueueButton code={code} value={value} setDisabled={setDisabled} />;
      } else if (code === 'request_form_doc_acceptance_ddo_short.child_iin') {
        return <DocAcceptanceDDOShortButton code={code} value={value}/>
      } else if (code === 'request_form_doc_queued_ddo_short.choice_ddo') {
        return (
          <StyledGBDFLButton
            color="secondary"
            size="small"
            text={i18next.t('serviceRequest_selectDDO')}
            onClick={() => selectDDOModal.open(true)}
          />
        );
      }
      break;
    default:
      return null;
  }
}
