import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    home_title: 'Портал электронных услуг акимата Алматинской области',
    home_subtitle: 'Предоставляемые услуги',
    home: 'Главная',
    home_support: 'Служба поддержки',
    home_frequently_asked_questions: 'Часто задаваемые вопросы',
    home_user_guide: 'Руководство пользователя',
    home_user_video: 'Видеоинструкция пользователя',

    home_insert_title: 'Получите госуслугу быстро, удобно, легко',
    home_insert_text: 'Экономьте ваше время и нервы',
    home_insert_btn: 'Войти в личный кабинет',
    home_order_service: 'Заказать услугу',
  },
  kk: {
    home_title: 'Алматы облысы Әкімдігінің Электронды қызметтер Порталы',
    home_subtitle: 'Ұсынылатын қызметтер',
    home: 'Басты бет',
    home_support: 'Қолдау қызметі',
    home_frequently_asked_questions: 'Жиі қойылатын сұрақтар',
    home_user_guide: 'Пайдаланушы нұсқаулығы',
    home_user_video: 'Пайдаланушының бейне нұсқаулығы',

    home_insert_title: 'Мемлекеттік қызметті тез, ыңғайлы әрі оңай алыңыз',
    home_insert_text: 'Уақыт пен жүйкеңізді үнемдеңіз',
    home_insert_btn: 'Жеке кабинетке кіру',
    home_order_service: 'Қызметке тапсырыс беру',
  }
});