import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    loginModal_title: 'Авторизация',
    loginPage_labelLogin: 'Логин',
    loginPage_placeholderLogin: 'Введите ваш логин',
    loginPage_labelPassword: 'Пароль',
    loginPage_placeholderPassword: 'Введите ваш пароль',
    loginPage_loginWithECP: 'Войти с ЭЦП',
    loginPage_login: 'Войти',

    loginDucks_authError: 'Не удалось авторизоваться',
    loginDucks_incorrectUsernamePassword: 'Введен неверный логин или пароль',
    loginDucks_userNotFound: 'Пользователь не найден в системе',
    loginDucks_signatureVerificationFail:
      'Ошибка ЭЦП, сертификат просрочен или некорректная подпись',
    loginDucks_userNotActive: 'Пользователь не активирован',
    loginPage_disclaimer: 'Уведомление об ответственности Я, пользователь портала электронных услуг акимата Алматинской области, продолжаю работу, подтверждаю свое согласие на передачу своих персональных данных для их обработки, в соответствии с Законом РК "О персональных данных и их защите"',
  },
  kk: {
    loginModal_title: 'Авторизация',
    loginPage_labelLogin: 'Логин',
    loginPage_placeholderLogin: 'Введите ваш логин',
    loginPage_labelPassword: 'Пароль',
    loginPage_placeholderPassword: 'Введите ваш пароль',
    loginPage_loginWithECP: 'ЭЦҚ арқылы кіру',
    loginPage_login: 'Войти',

    loginDucks_authError: 'Авторизациядан  өту мүмкін емес',
    loginDucks_incorrectUsernamePassword: 'Логин немесе құпия сөз қате енгізілді',
    loginDucks_userNotFound: 'Пайдаланушы жүйеде табылмады',
    loginDucks_signatureVerificationFail:
      'ЭЦҚ қатесі, сертификат мерзімі өткен немесе қол қате',
    loginDucks_userNotActive: 'Пользователь не активирован',
    loginPage_disclaimer: 'Жауапкершілік туралы хабарлама Мен, Алматы облысы әкімдігінің электронды қызметтер порталының пайдаланушысы, жұмысымды жалғастыра отырып, Қазақстан Республикасының Заңына сәйкес өзімнің жеке деректерімді өңдеу үшін беруге келісімімді растаймын.',
  }
})