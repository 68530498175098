import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kindergarten_appointments_subtitle: 'Направления',
    kindergarten_appointments_number: '№',
    kindergarten_appointments_appointment_number: '№ Направления',
    kindergarten_appointments_iin_child: 'ИИН ребенка',
    kindergarten_appointments_requestNumber: '№ Заявления',
    kindergarten_appointments_fio_child: 'ФИО ребенка',
    kindergarten_appointments_status: 'Статус',
    kindergarten_appointments_ddo: 'Дошкольная организация',
    kindergarten_appointments_group_name: 'Наименование группы',
    kindergarten_appointments_date_given: 'Дата выдачи направления',
    kindergarten_appointments_expiration_date: 'Срок действия направления',
    kindergarten_appointments_actions_extend: 'Продлить срок действия направления',
    kindergarten_appointments_actions_review: 'Отозвать направление',
    kindergarten_appointments_actions_download: 'Скачать направление',
    kindergarten_appointments_actions_enroll_do: 'Зачислиться в ДО',
    kindergarten_appointments_error_load: 'Ошибка при загрузке данных',
    kindergarten_appointments_error_revoke_applicant: 'Не удалось отозвать направление',
    kindergarten_appointments_error_prolong_applicant: 'Не удалось продлить направление',
    kindergarten_appointments_modal_review_body:
      'Вы действительно хотите отозвать полученное направление? При отзыве, полученное направление будет аннулировано, и ребенок будет восстановлен в очередь',
    kindergarten_appointments_modal_yes: 'Да',
    kindergarten_appointments_modal_no: 'Нет',
    kindergarten_appointments_modal_extend_yes: 'Продлить направление',
    kindergarten_appointments_modal_extend_no: 'Отмена',
    kindergarten_appointments_modal_extend_title: 'Продления направления',
    kindergarten_appointments_modal_extend_body:
      'Функция продления срока действия направления доступна только один раз на срок до 30 (тридцати) календарных дней с обязательным приложением подтверждающих документов и заявителю необходимо явиться в дошкольную организацию в установленный срок',
    kindergarten_appointments_modal_extend_date: 'Укажите срок продления',
    kindergarten_appointments_modal_extend_cause: 'Причина продления',
    kindergarten_appointments_modal_extend_file: 'Прикрепление подтверждающего документа'
  },
  kk: {
    kindergarten_appointments_subtitle: 'Жолдамалар',
    kindergarten_appointments_number: '№',
    kindergarten_appointments_appointment_number: ' Жолдама №',
    kindergarten_appointments_iin_child: 'Баланың ЖСН-і',
    kindergarten_appointments_requestNumber: 'Өтініш №',
    kindergarten_appointments_fio_child: 'Баланың аты-жөні',
    kindergarten_appointments_status: 'Мәртебе',
    kindergarten_appointments_ddo: 'Мектепке дейінгі ұйым',
    kindergarten_appointments_group_name: 'Топтың атауы',
    kindergarten_appointments_date_given: 'Жолдаманың берілу мерзімі',
    kindergarten_appointments_expiration_date: 'Жолдаманың жарамдылық мерзімі',
    kindergarten_appointments_actions_extend: 'Жолдаманың қолданылу мерзімін ұзарту',
    kindergarten_appointments_actions_review: 'Жолдаманы қайтарып алу',
    kindergarten_appointments_actions_download: 'Жолдаманы жүктеу',
    kindergarten_appointments_actions_enroll_do: 'МДҰ-ға тіркелу',
    kindergarten_appointments_error_load: 'Деректерді жүктеуде қателік орын алды',
    kindergarten_appointments_error_revoke_applicant:
      'Жолдаманы қайтарып алу мүмкіндігі болмады',
    kindergarten_appointments_error_prolong_applicant:
      'Жолдаманың жарамдылық мерзімін ұзарту мүмкіндігі болмады',
    kindergarten_appointments_modal_review_body:
      'Сіз алынған жолдаманы қайтарып алғыңыз келеді ме? Кері қайтарып алу кезінде алынған жолдама жойылады және бала кезекке қайтарылады',
    kindergarten_appointments_modal_yes: 'Иә',
    kindergarten_appointments_modal_no: 'Жоқ',
    kindergarten_appointments_modal_extend_yes: 'Жолдаманы ұзарту',
    kindergarten_appointments_modal_extend_no: 'Болдырмау',
    kindergarten_appointments_modal_extend_title: 'Жолдаманы ұзарту',
    kindergarten_appointments_modal_extend_body:
      'Жолдаманың қолданылу мерзімін ұзарту функциясы 30 (отыз) күнтізбелік күнге дейінгі мерзімге растайтын құжаттарды міндетті түрде бекіте отырып, бір рет ғана қолжетімді және өтініш беруші белгіленген мерзімде мектепке дейінгі ұйымға келуі қажет',
    kindergarten_appointments_modal_extend_date: 'Ұзарту мерзімін көрсетіңіз',
    kindergarten_appointments_modal_extend_cause: 'Мерзімді ұзарту себебі',
    kindergarten_appointments_modal_extend_file: 'Растайтын құжатты бекіту'
  }
});
