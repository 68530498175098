import React from 'react';
import { useTranslation } from 'react-i18next';
import { FooterCardText } from './FooterStyle';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import useUserInfo from '../_hooks/useUserInfo';
import { checkAuth } from '../../utils/needAuth';
import { loginModalHandler } from '../../pages/LoginPage/LoginDucks';
import { history } from '../../_helpers/history';

export const useStyles = makeStyles({
  typography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#fff',
    fontSize: '14px',
    lineHeight: '140%',
    fontWeight: '400',
    cursor: 'pointer',
  }
});

function FooterItem({ metadata }) {
  const { i18n } = useTranslation();
  const { typography } = useStyles();
  const dispatch = useDispatch();
  const user = useUserInfo();
  const needAuth = checkAuth(metadata.url);

  return (
    <>
      {
        (user.username || !needAuth) ? 
          <FooterCardText>
            <Typography
              color="secondary"
              children={metadata[`${i18n.language}_name`]}
              className={typography}
              onClick={() => {
                if (!metadata.url) {
                  history.push(`/services/create/${metadata.code}`);
                } else {
                  history.push(metadata.url);
                }
              }}
            />
          </FooterCardText>
          : 
          <FooterCardText>
            <Typography 
              color="secondary"
              onClick={() => dispatch(loginModalHandler(true, metadata))}
              children={metadata[`${i18n.language}_name`]} 
              className={typography} 
            />
          </FooterCardText>
        }
    </>
  );
}

export default FooterItem;