import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HeaderWithoutAuth from './Headers/HeaderWithoutAuth';
import { loginModule } from '../../pages/LoginPage/LoginDucks';
import HeaderWithAuth from './Headers/HeaderWithAuth';

function Header({contentWidth, isCurrent}) {
  const { i18n } = useTranslation();
  const { user } = useSelector(state => state[loginModule]);
  useEffect(() => {
    let lsLang = localStorage.getItem('lang');
    if (lsLang && lsLang !== i18n.language) {
      i18next.changeLanguage(lsLang);
    } else {
      localStorage.setItem('lang', i18n.language);
    }
  }, [i18n.language]);

  return (
    <>
      {
        !user
          ? <HeaderWithoutAuth contentWidth={contentWidth} isCurrent={isCurrent} />
          : <HeaderWithAuth contentWidth={contentWidth} isCurrent={isCurrent} />
      }
    </>
  );
}

export default Header;
