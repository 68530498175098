import React from 'react';
import './MarketTranslate';
import playMarket from '../../_assets/images/playMarket.png'
import appStore from '../../_assets/images/appStore.png'
import playMarketApp from './../../_assets/files/playMarketApp1-7-9.apk'

import { useTranslation } from 'react-i18next';

function MarketAndroidPage() {
  const { t } = useTranslation();
 
  return (
    <div style={{
      width: '100%',
      height: '100vh',
      backgroundColor: '#fff',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 9999,
    }}>
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
      }}>
        <a href={playMarketApp} download>
          <img src={playMarket} style={{ width: '180px' }} alt="" />
        </a>
        <br/>
        <a
          href='https://apps.apple.com/kz/app/%D1%83%D1%87%D0%B5%D1%82-%D0%BF%D0%BE%D1%81%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D0%B9/id6472644316'>
          <img src={appStore} style={{width: '165px'}} alt=''/>
        </a>
      </div>
    </div>
  );
}

export default MarketAndroidPage;
