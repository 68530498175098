import React from 'react';
import playMarket from '../../_assets/images/playMarket.png'
import playMarketApp from './../../_assets/files/playMarketApp1-7-9.apk'

function MarketPlay() {
  return (
    <a href={playMarketApp} download>
      <img src={playMarket} alt="" />
    </a>
  );
}

export default MarketPlay;
