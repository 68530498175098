import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import LinkApp from './LinkApp'
import './MarketTranslate'
import {StyledMarketText, StyledBlockClose} from './MarketStyle'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export default function DialogMarket({ open, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledBlockClose>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </StyledBlockClose>
      <StyledMarketText>{t('modal_title')}</StyledMarketText>
      <LinkApp />
    </Dialog>
  );
}