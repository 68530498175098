import React from 'react';
import { useTranslation } from 'react-i18next';
import './../KindergartenServicesTranslate';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { history } from '../../../_helpers/history';
import useUserInfo from '../../../components/_hooks/useUserInfo';
import { loginModalHandler } from '../../../pages/LoginPage/LoginDucks';
import { checkAuth } from '../../../utils/needAuth';
import { withStyles } from '@material-ui/styles';

const MyButton = withStyles((theme) => ({
    root: {
        marginRight: '30px',
        padding: '20px 40px',
        fontWeight: '700',
        fontSize: '14px',
        textTransform: 'uppercase',
        letterSpacing: '0.01em',
        fontFamily: 'Montserrat',
        backgroundColor: '#5250F9',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#3433B8',
        },
        '@media (max-width:1000px)': {
            marginBottom: '15px',
            padding: '15px 35px',
        },
        '@media (max-width:500px)': {
            marginBottom: '15px',
            padding: '10px 25px',
            marginRight: '0px',
        }
    }
}))(Button);

function InsertBtn({ metadata }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUserInfo();
  const needAuth = checkAuth(metadata.url);
  return (
    <>
        {
            (user.username || !needAuth)
                ? 
                <MyButton
                    variant="contained"
                    onClick={() => {
                        if (!metadata.url) {
                        history.push(`/services/create/${metadata.code}`);
                        }else{
                        history.push(metadata.url);
                        }
                    }}
                    children={metadata[`${i18n.language}_text`]}
                />
                : 
                <MyButton
                    variant="contained"
                    onClick={() => dispatch(loginModalHandler(true, metadata))}
                    children={metadata[`${i18n.language}_text`]}
                />
        }
    </>
  );
}

export default InsertBtn;
