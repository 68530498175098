import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    check_queue_number_title: 'Централизованная база данных подведомственных учреждений акимата Алматинской области',
    check_queue_number_subtitle: 'Проверка номера в очереди',
    check_queue_number_but: 'Проверить',
    check_queue_number_put_iin: 'Введите ИИН ребенка',
    check_queue_number_iin: 'ИИН',
    check_queue_number_table_n: '№ Заявки:',
    check_queue_number_table_fio: 'ФИО ребенка:',
    check_queue_number_table_queue_number: 'Номер очереди:',
    check_queue_number_table_queue_type: 'Тип очереди:',
    check_queue_number_table_priority: 'Приоритетность в очереди:',
    check_queue_number_table_app_date: 'Дата подачи заявки:',
    check_queue_requiredField: 'Обязательно для заполнения',
    check_queue_correctData: 'Введите корректный ИИН',
    check_queue_number_table_region: 'Регион:',
  },
  kk: {
    check_queue_number_title: 'Алматы облысы әкімдігінің ведомстволық бағыныстағы мекемелерінің орталықтандырылған деректер базасы',
    check_queue_number_subtitle: 'Кезектегі нөмірді тексеру',
    check_queue_number_but: 'Тексеру',
    check_queue_number_put_iin: 'Баланың ЖСН-ін теріңіз',
    check_queue_number_iin: 'ЖСН',
    check_queue_number_table_n: 'Өтініш №:',
    check_queue_number_table_fio: 'Баланың аты-жөні:',
    check_queue_number_table_queue_number: 'Кезектегі нөмірі:',
    check_queue_number_table_queue_type: 'Кезек түрі:',
    check_queue_number_table_priority: 'Кезек басымдылығы:',
    check_queue_number_table_app_date: 'Өтініштің берілу күні:',
    check_queue_requiredField: 'Толтыру міндетті',
    check_queue_correctData: 'Дұрыс ЖСН енгізіңіз',
    check_queue_number_table_region: 'Аймақ:',
  }
});
