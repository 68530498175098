import styled from 'styled-components';

export const FooterSection = styled.div`
  width: 100%;
  background-color: #3433B8;
  padding: 40px 0;
  z-index: 1;
`;

export const FooterCardText = styled.div `
  margin-top: 20px;
  line-height: 140%;
  cursor: pointer;
`

export const FooterTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & img {
    width: 70px;
    height: auto;
  }
  & div {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 25px;
    color: white;
    width: 300px;
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    display: block;
    & div {
      width: 100%;
      max-width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 15px;
    }
  }
`

export const FooterCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  padding: 0 72px;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
`;

export const FooterCard = styled.div`
 width: 100%;
`

export const FooterWrapperText = styled.div`
  display: flex;
  justify-content: flex-end;
  & a {
    margin-left: 30px;
    display: block;
  }
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    width: 100%;
    max-width: 100%;
    & div {
      width: 50%;
      max-width: 50%;
      a {
        margin-left: 0px;
        text-align: center;
      }
    }
  }
`