import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import { StyledDialog, StyledLink } from './InstructionsStyles';
import RuInstruction from '../../_assets/files/instruction_ru.pdf';
import KkInstruction from '../../_assets/files/instruction_kk.pdf';
import './InstructionsTranslate';

const instructions = [
  {
    ru: RuInstruction,
    kk: KkInstruction,
    ru_name: 'Прием документов и зачисление в организации образования (начальные, осн. ср., общ.ср, спец.)',
    kk_name: 'Білім беру ұйымдарына құжаттарды қабылдау және оқуға қабылдау (мектепке дейінгі, бастауыш, негізгі орта, жалпы орта, арнайы)',
  },
];

function Instructions() {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(null);

  return (
    <div className="mb3 mt3 fullWidth">
      <Typography
        children={t('instructions_title')}
        color="secondary"
        variant="h6"
      />
      <div className="mb3"/>
      {instructions.map(
        (item, key) =>
          <Card
            key={key}
            className="my1 p2 pointer flex items-center"
            variant="outlined"
            onClick={() => setSelected(item)}
          >
            <RightIcon color="action" className="mr2"/>
            <StyledLink>{item[`${i18n.language}_name`]}</StyledLink>
          </Card>
      )}
      {selected && (
        <StyledDialog open={true} onClose={() => setSelected(null)} maxWidth="xl">
          <div className="p2 bold center">{selected[`${i18n.language}_name`]}</div>
            
          <object
            width="100%"
            data={selected[i18n.language]}
            style={{ height: '100vh' }}
          >
          </object>
        </StyledDialog>
      )}
    </div>
  );
}

export default Instructions;