import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './HeaderTranslate';
import useModal from '../../../modules/_hooks/useModal';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../modules/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { headerModule, setConsentCollection } from '../HeaderDucks';
import {Checkbox} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';

function CollectionBlock() {
  const { t } = useTranslation();
  const modalAgreement = useModal('agreement');
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state[headerModule]);
  const [type, setType] = useState('agree');
  return (
    <>
      <div style={{
        backgroundColor: '#5250F9',
        width: '100%',
        padding: '15px',
        color: '#fff',
        textAlign: 'center',
        fontSize: '16px',
      }}>
        {t('header_agreement')}
        <span onClick={() => modalAgreement.open(true)} style={{cursor: 'pointer', borderBottom: '1px solid #fff'}}> {t('header_agreementLink')} </span>

      </div>
      <Dialog
        open={modalAgreement.isOpen}
        onClose={modalAgreement.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{marginBottom: 0}}>
          <p style={{ textIndent: '16px', marginBottom: '0', textAlign: 'justify' }}>
            {t('header_agreementModalText')}
          </p>
        </DialogTitle>
        <DialogContent>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              onChange={() => {
                if (type === 'agree') {
                  setType('disagree')
                } else {
                  setType('agree')
                }
              }}
              id='check'
            />
            <label for='check'>
              {t('header_agreementDonSign')}
            </label>
          </div>
          {
            type === 'disagree' &&
            <p style={{color: 'red'}}>
              {t('header_agreementDonSignText')}
            </p>
          }
          
      </DialogContent>
        <DialogActions style={{ textAlign: "center" }}>
          <div style={{display: 'flex' }}>

            <Button
              onClick={
                () => {
                  dispatch(setConsentCollection({
                    close: modalAgreement.close,
                    type: type
                  }));
                  setType('agree')
                }
              }
              loading={loading}
              color="secondary"
              variant="contained"
              text={t('header_agreementSign')}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CollectionBlock;
