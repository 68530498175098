import React, { useEffect } from 'react';
import {StyledContent} from './HomeStyles';
import './HomeTranslate';
import HomeInsert from './section/HomeInsert'
import HomeServices from './section/HomeServices'
import { getContentWidth } from '../../utils/getContentWidth';
import DialogMarket from '../Market/ModalAppMarket';
import useModal from '../../modules/_hooks/useModal';
import { useLocation } from 'react-router-dom';

function Home() {
  const modalMarket = useModal('market');
  const location = useLocation();
  const contentWidth = getContentWidth('/kindergarten-services');
  useEffect(() => {
    if (location.search !== '?agreement=true') {
      modalMarket.open({})
    }
  }, [])

  return (
    <div style={{width: '100%'}}>
      <StyledContent>
        <HomeInsert contentWidth={contentWidth} />
      </StyledContent>
      <HomeServices contentWidth={contentWidth}/>
      <DialogMarket
        open={modalMarket.isOpen}
        handleClose={modalMarket.close}
      />
    </div>
  );
}

export default Home;
