import { createReducer } from 'redux-starter-kit';
import { history } from '../../_helpers/history';
import routerProps from '../../_helpers/routerProps';
import paths from '../../_helpers/paths';
import { BulletinOpenPlacesApi } from './BulletinOpenPlacesApi';
import Notice from '../../modules/utils/Notice';
import Dict from '../../modules/utils/Dict';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import { handleError } from '../../modules/utils/handleError';
import i18next from 'i18next';
import { KindergartenApplicationsApi } from '../KindergartenApplications/KindergartenApplicationsApi';

/**
 * Constants
 */

export const bulletinOpenPlacesModule = 'bulletinOpenPlaces';
const SET_DATA = `${bulletinOpenPlacesModule}/SET_DATA`;
const LOADING = `${bulletinOpenPlacesModule}/LOADING`;
const CLEAR_STATE = `${bulletinOpenPlacesModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data: [],
  pageNumber: 1,
  totalElements: 0
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.data;
    state.pageNumber = action.pageNumber;
    state.totalElements =
      action.pageNumber === 1 ? action.totalElements : state.totalElements;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [CLEAR_STATE]: () => initialState
});

export const clearState = () => ({ type: CLEAR_STATE });

export const loadBulletins = (lang, filter, KqId) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, loading: true });
    let languages = await Dict.items('gu_language');
    let age_groups = await Dict.items('gu_age_group');
    let bulletin_kinds = await Dict.items('gu_type_bulletin');
    let queue_types = await Dict.items('gu_queued_type');
    let ownerships = await Dict.items('gu_form_ownership');
    let begin_academic_years = await Dict.items('gu_academic_year_do');
    let years_queue = await Dict.items('gu_year_queue');
    const { data } = await BulletinOpenPlacesApi.getBulletin(filter);
    if (data.content) {
      let res = getKeyValues(data.content);
      res.map(bulletin => {
        bulletin.canGetNapravlenie = false;
        let age_group = age_groups.find(function(item) {
          return item.code === bulletin.age_group.value;
        });
        let bulletin_kind = bulletin_kinds.find(function(item) {
          return item.code === bulletin.type_bulletin.value;
        });
        let language = languages.find(function(item) {
          return item.code === bulletin.gu_language.value;
        });
        let queue_type = queue_types.find(function(item) {
          return item.code === bulletin.queue_type;
        });
        let academic_year = begin_academic_years.find(function(item) {
          return item.code === bulletin.begin_academic_year.value
        })
        let year_queue = years_queue.find(function(item) {
          return item.code === bulletin.year_queue.value
        })
        if (bulletin.form_ownership) {
          let ownership = ownerships.find(function(item) {
            return item.code === bulletin.form_ownership.value;
          });
          bulletin.ownerships = {
            ru: ownership?.ru_name,
            kk: ownership?.kk_name,
          }
        }
        bulletin.queue_types = {
          kk: queue_type?.kk_name,
          ru: queue_type?.ru_name
        }
        bulletin.gu_languages = {
          kk: language?.kk_name,
          ru: language?.ru_name,
        }
        bulletin.type_bulletins = {
          kk: bulletin_kind?.kk_name,
          ru: bulletin_kind?.ru_name,
        }
        bulletin.age_groups = {
          kk: age_group?.kk_name,
          ru: age_group?.ru_name,
        }
        bulletin.academic_years = {
          kk: academic_year?.kk_name,
          ru: academic_year?.ru_name,
        }
        bulletin.year_queues = {
          ru: year_queue?.ru_name,
          kk: year_queue?.kk_name,
        }
      });
      if(KqId){
        res = await checkCanGetNapravlenie(res, KqId, getState().kindergartenApplications.data);
      }
      dispatch({
        type: SET_DATA,
        data: res,
        pageNumber: data.pageNumber,
        totalElements: data.totalElements
      });
    } else {
      Notice.error('Ошибка запроса бюллетеней');
    }
  } catch (error) {
    console.log(error);
    let location = window.location;
    for (const route of Object.values(routerProps)) {
      if (route.path === location.pathname && !route.withoutAuth) {
        window.addressBarUrl = `${location.pathname}${location.search}`;
        history.push(paths.homePage);
      }
    }
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

const checkCanGetNapravlenie = async (bulletins, KqId, queueApps) => {
  try {
    let item = queueApps.find(el => el.id.toString() === KqId);
    if (!item){
      item = await getApplication(KqId);
    }
    if(!item){
      return bulletins;
    }
    if(item.appStatus !== 'IN_QUEUED' && item.appStatus !== 'PRIORITY_ACCESS' && item.appStatus !== 'WAITING_PRIORITY'){
      return bulletins;
    }
    let filter = {
      locationCode: item.queuedRegion,
      statusWeb: item.appStatus === 'IN_QUEUED' ? 'available_all' : null,
      childBirthDay: item.childBirthday
    }
    const response = await BulletinOpenPlacesApi.getBulletin(filter);
    if (response.data.content) {
      let res = getKeyValues(response.data.content);
      res.forEach(el => {
        if (el.available_sits > 0) {
          bulletins.forEach(bulletin => {
            if (bulletin.id === el.id) {
              console.log(bulletin.id);
              bulletin.canGetNapravlenie = true;
            }
          })
        }
      });
      return bulletins;
    } else {
      Notice.error(i18next.t('kindergarten_applications_check_bulletin_error'));
    }
  } catch (error) {
    console.log(error);
    handleError(error, i18next.t('kindergarten_applications_check_bulletin_error'));
  }
}

const getApplication = async (id) => {
  try {
    const { data } = await KindergartenApplicationsApi.getApplications(JSON.stringify({ id: id }));
    if (data.status === 'SUCCESS' && data.result.content.length > 0) {
      return data.result.content[0];
    } else {
      Notice.error(i18next.t('kindergarten_applications_error_load'));
      return false
    }
  } catch (e) {
    handleError(e, i18next.t('kindergarten_applications_check_bulletin_error'));
    return false
  }
}
const getKeyValues = data => {
  let res = [];
  data.forEach(item => {
    let obj = {};
    item.passportValues.forEach(a => {
      obj[a.code] = a.value;
    });
    res.push(obj);
  });
  return res;
};
export const createDirection = (dataForm, modal, modalSuccess) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await BulletinOpenPlacesApi.signStringDirection(dataForm);
    const signature = await NCALayerSign(data.result.signature);
    if (signature) {
      const res = await BulletinOpenPlacesApi.createDirectionApi({
        signature,
        ...dataForm
      });
      if(res.data.entity){
        modal.close();
        modalSuccess.open(res.data.entity);
      }else{
        Notice.error(i18next.t('bulletin_op_get_appointment_error'));
      }
    }
  } catch (error) {
    if (error.response.data.errors[0]?.code === 'error.runtime_place_not_found_error') {
      Notice.error(i18next.t('bulletin_op_places_over'));
    } else {
      handleError(error, i18next.t('bulletin_op_get_appointment_error'));
    }
  } finally {
    modal.close();
    dispatch({ type: LOADING, loading: false });
  }
};
