import React from 'react';
import { useTranslation } from 'react-i18next';
import {StyledServiceCard, StyledServiceCardImg, StyledServiceCardContent, 
    StyledServiceCardTitle, StyledServiceCardText, StyledServiceCardBtn } from '../KindergartenServicesStyle';
import './../KindergartenServicesTranslate';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { history } from '../../../_helpers/history';
import useUserInfo from '../../../components/_hooks/useUserInfo';
import { loginModalHandler } from '../../../pages/LoginPage/LoginDucks';
import { checkAuth } from '../../../utils/needAuth';
import { withStyles } from '@material-ui/styles';

const ColorButton = withStyles((theme) => ({
    root: {
        padding: '12px 40px',
        fontWeight: '700',
        fontSize: '14px',
        textTransform: 'uppercase',
        letterSpacing: '0.01em',
        fontFamily: 'Montserrat',
        '@media (max-width:1000px)': {
            padding: '15px 30px',
        }
    }
}))(Button);

function OurServiceCard({ metadata }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUserInfo();
  const needAuth = checkAuth(metadata.url);
  return (
    <StyledServiceCard>
        <StyledServiceCardContent>
            <StyledServiceCardImg>
                <img src={metadata[`img`]} alt={metadata[`${i18n.language}_name`]} />
            </StyledServiceCardImg>
            <StyledServiceCardTitle children={metadata[`${i18n.language}_name`]} />
            <StyledServiceCardText children={metadata[`${i18n.language}_text`]} />
        </StyledServiceCardContent>
        <StyledServiceCardBtn>
            {
                (user.username || !needAuth) ? 
                <ColorButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        if (user.username) {
                            if (!metadata.url) {
                                history.push(`/services/create/${metadata.code}`);
                            } else {
                                history.push(metadata.url);
                            }
                        } else {
                            dispatch(loginModalHandler(true, metadata))
                        }
                    }}
                    children={t('kindergarten_go')}
                />
                : 
                <ColorButton
                    variant="contained"
                    color="secondary"
                    onClick={() => dispatch(loginModalHandler(true, metadata))}
                    children={t('kindergarten_go')}
                />
            }
        </StyledServiceCardBtn>
    </StyledServiceCard>
  );
}

export default OurServiceCard;
