let config;
let protocol = window.location.protocol;

switch (window.location.hostname) {
  case 'es-portal.e-zhetysu.kz':
  case 'e-zhetysu.kz':
    config = {
      url: {
        base: `${protocol}//es-api.e-zhetysu.kz`,
        service: `${protocol}//es-services.e-zhetysu.kz`,
        fileStore: `${protocol}//es-filestore-api.e-zhetysu.kz`,
        kezek: `${protocol}//kezek.e-zhetysu.kz/#/`
      }
    };
    break;
  case 'es-portal-test.e-zhetysu.kz':
  case 'test.e-zhetysu.kz':
    config = {
      url: {
        base: `${protocol}//es-api-test.e-zhetysu.kz`,
        service: `${protocol}//es-services-test.e-zhetysu.kz`,
        fileStore: `${protocol}//es-filestore-api-test.e-zhetysu.kz`,
        kezek: `${protocol}//kezek-test.e-zhetysu.kz/#/`
      }
    };
    break;
  case 'gu.almobl.kz':
    config = {
      url: {
        base: `${protocol}//api.gu.almobl.kz`,
        service: `${protocol}//services.gu.almobl.kz`,
        fileStore: `${protocol}//filestore-api.gu.almobl.kz`
      }
    };
    break;
  default: {
    protocol = 'https:';
    config = {
      url: {
        base: `${protocol}//es-api-test.e-zhetysu.kz`,
        service: `${protocol}//es-services-test.e-zhetysu.kz`,
        fileStore: `${protocol}//es-filestore-api-test.e-zhetysu.kz`,
        kezek: `${protocol}//kezek-test.e-zhetysu.kz/#/`
      }
    };
  }
}

export default config;
