import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { changeFormValue } from '../../../passport/Passport';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import PassportForm from '../../../passport/FormData/PassportForm';
import {saveBinFunder} from './../ServiceRequestDucks'
import { useDispatch } from 'react-redux';

const requestGBDFL = async ({ setLoading, code, value, t, lang, dispatch }) => {
  try {
    let queue_type = PassportForm.values['request_form_doc_acceptance_ddo_short.queued_type'];
    setLoading(true);
    const metadataKey = code.split('.')[0];
    const data = await ServiceRequestApi.searchKindergartenQueue(value, queue_type);
    const info = data.data.result
    if (data.status === 200) {      
      const surname = _.get(info, 'questionnaire.questionCodeToAnswers.child_surname.values[0].value');
      const name = _.get(info, 'questionnaire.questionCodeToAnswers.child_name.values[0].value');
      const middlename = _.get(info, 'questionnaire.questionCodeToAnswers.child_middlename.values[0].value');
      const birthday = _.get(info, 'questionnaire.questionCodeToAnswers.child_birthday.values[0].value');
      const language = _.get(info, 'direction.bulletin.questionnaire.questionCodeToAnswers.language.values[0].value');
      const userCategory = _.get(info, 'questionnaire.questionCodeToAnswers.user_category.values[0].value');
      const kindergardenGroup = _.get(info, 'direction.bulletin.questionnaire.questionCodeToAnswers.age_group.values[0].value');
      const nameGroupKk = _.get(info, `direction.bulletin.questionnaire.questionCodeToAnswers.name_group_kk.values[0].value`);
      const nameGroupRu = _.get(info, `direction.bulletin.questionnaire.questionCodeToAnswers.name_group_ru.values[0].value`);

      dispatch(saveBinFunder(info.direction.serviceProviderBin))
      switch (code) {
        case 'request_form_doc_acceptance_ddo_short.child_iin':
          changeFormValue(`${metadataKey}.child_surname`, surname);
          changeFormValue(`${metadataKey}.child_middlename`, middlename);
          changeFormValue(`${metadataKey}.child_name`, name);
          changeFormValue(`${metadataKey}.child_birthday`, birthday);
          changeFormValue(`${metadataKey}.user_category`, userCategory);
          changeFormValue(`${metadataKey}.direction_num`, info.directionId);
          changeFormValue(`${metadataKey}.direction_region`, info.queuedRegion);
          changeFormValue(`${metadataKey}.kindergarden_name`, info.direction.serviceProviderRuName);
          changeFormValue(`${metadataKey}.kindergarden_name_kz`, info.direction.serviceProviderKkName);
          changeFormValue(`${metadataKey}.kindergarden_group`, kindergardenGroup);
          changeFormValue(`${metadataKey}.lang_study`, language);
          changeFormValue(`${metadataKey}.direction_date`, info.direction.directionDate);
          changeFormValue(`${metadataKey}.name_group_kk`, nameGroupKk);
          changeFormValue(`${metadataKey}.name_group_ru`, nameGroupRu);

          break
        default:
          break;
      }
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.kq_with_active_direction_not_found') {
      Notice.error(t('gbdflButton_iinNotFound_3'));
    } else if (errorCode === 'error.kq_requester_iin_doesnt_match') {
      Notice.error(t('gbdflButton_iinNotFound_4'));
    }  else {
      handleError(error, t('gbdflButton_searchFailed'));
    }
  } finally {
    setLoading(false);
  }
};

function DocAcceptanceDDOShortButton({ code, value }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <div>
      <StyledGBDFLButton
        color="secondary"
        loading={loading}
        text={t('gbdflButton_search')}
        disabled={value.length !== 12}
        onClick={() => requestGBDFL({ setLoading, code, value, t, lang: i18n.language, dispatch })}
      />
    </div>
  );
}

export default DocAcceptanceDDOShortButton;