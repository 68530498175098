import styled from 'styled-components';

export const StyledTable = styled.div`
  .row1, .row2{
    display: flex;
  }
  .row2{
    padding-top: 10px;
  }
  .col1 div{
    display: inline-block;
    padding-right: 20px;
  }
  .col1{
    width: 70%;
  }
  .col2{
    width: 30%;
    text-align: center;
    white-space: pre-wrap;
  }
  .col2_1{
    width: 40%;
    white-space: pre-wrap;
  }
  .col2_1 div{
    padding-bottom: 5px;
  }
  .col2_2{
    width: 30%;
    padding: 0px 10px;
    text-align: center;
  }
  .col2_3{
    width: 30%;
    text-align: center;
  }
  .tab_but{
    width: 209px;
    height: 30px;
  }
  .tab_but_revoke{
    margin-top: 5px;
    width: 209px;
    background: rgba(0,0,0,0.05);
    color: black;
  }
  .tab_but_revoke:hover{
    margin-top: 5px;
    width: 209px;
    background: rgba(0,0,0,0.09);
    color: rgba(255,0,0,1);
    text-decoration: underline;
  }
`;