import React from 'react';
import { useTranslation } from 'react-i18next';
import {StyledQuestions, StyledTitle, StyledQuestionsCards, StyledQuestionsCard, StyledQuestionsCardImg} from '../KindergartenServicesStyle';
import './../KindergartenServicesTranslate';
import { Content } from '../../../components/ThemeProvider/ThemeStyle';
import support from '../../../_assets/images/Support.png'
import FrequentlyaskedQuestions from '../../../_assets/images/FrequentlyaskedQuestions.png'
import usersManual from '../../../_assets/images/usersManual.png'
import videoUserManual from '../../../_assets/images/videoUserManual.png'
import paths from '../../../_helpers/paths';
import { Link } from 'react-router-dom';
import RuKindergartenInstruction from '../../../_assets/files/ru_kindergarten_instruction.pdf';
import KKkindergartenInstruction from '../../../_assets/files/kk_kindergarten_instruction.pdf';


function KindergartenQuestions({contentWidth}) {
  const { t, i18n } = useTranslation();

  return (
    <StyledQuestions>
        <Content contentWidth={contentWidth}>
            <StyledTitle children={t('kindergarten_article_h1')} />
        </Content>
        <Content contentWidth={contentWidth}>
            <StyledQuestionsCards>
                <StyledQuestionsCard>
                    <div 
                        onClick={() => {
                            window.open('https://gualmobl.supportsystem.com/index.php', '_blank');
                        }}
                    >
                        <StyledQuestionsCardImg>
                            <img src={support} alt={t('kindergarten_support')}/>
                        </StyledQuestionsCardImg>
                        <p>{t('kindergarten_support')}</p>
                    </div>
                </StyledQuestionsCard>
                <StyledQuestionsCard>
                    <Link to={paths.faqPage}>
                        <StyledQuestionsCardImg>
                            <img src={FrequentlyaskedQuestions} alt={t('kindergarten_faq')}/>
                        </StyledQuestionsCardImg>
                        <p>{t('kindergarten_faq')}</p>
                    </Link>
                </StyledQuestionsCard>
                <StyledQuestionsCard>
                    <div
                        onClick={() => {
                            window.open(i18n.language === 'ru' ? RuKindergartenInstruction : KKkindergartenInstruction, '_blank');
                        }}
                    >
                        <StyledQuestionsCardImg>
                            <img src={usersManual} alt={t('kindergarten_guide')}/>
                        </StyledQuestionsCardImg>
                        <p>{t('kindergarten_guide')}</p>
                    </div>
                </StyledQuestionsCard>
                {/*<StyledQuestionsCard>
                    <Link to={paths.videoInstructionsPage}>
                        <StyledQuestionsCardImg>
                            <img src={videoUserManual} alt={t('kindergarten_video')}/>
                        </StyledQuestionsCardImg>
                        <p>{t('kindergarten_video')}</p>
                    </Link>
                    </StyledQuestionsCard>*/}
            </StyledQuestionsCards>
        </Content>
    </StyledQuestions>
  );
}

export default KindergartenQuestions;