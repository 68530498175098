import React from 'react';
import { useTranslation } from 'react-i18next';
import {StyledInsert, StyledInsertContent, StyledInsertTitle, StyledInsertText, StyledInsertBtn} from '../KindergartenServicesStyle';
import './../KindergartenServicesTranslate';
import { Content } from '../../../components/ThemeProvider/ThemeStyle';
import kindergartenServicesBg from '../../../_assets/images/kindergartenServicesBg.png';
import {kindergarten_services_insert} from './../KindergartenServicesItems';
import InsertBtn from './InsertBtn';

function KindergartenInsert({contentWidth}) {
  const { t } = useTranslation();

  return (
    <StyledInsert>
        <img src={kindergartenServicesBg} alt="" />
        <StyledInsertContent>
            <Content contentWidth={contentWidth}>
                <StyledInsertTitle children={t('kindergarten_insert_title')} />
            </Content>
            <Content contentWidth={contentWidth}>
                <StyledInsertText children={t('kindergarten_insert_text')} />
            </Content>
            <Content contentWidth={contentWidth}>
                {Object.values(kindergarten_services_insert).map((metadata, index) => (
                    <StyledInsertBtn key={index}>
                        <InsertBtn metadata={metadata} />
                    </StyledInsertBtn>
                ))}
            </Content>
        </StyledInsertContent>
    </StyledInsert>
  );
}

export default KindergartenInsert;
